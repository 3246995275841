.navbar-nav li:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.navbar-nav li:hover .nav-link {
    color: orange !important;
}

@media (max-width: 767px) {
    .navbar-nav li:hover .nav-link {
        color: orange !important;
    }

    .navbar-collapse {
        max-height: 200px;
        overflow-y: auto;
    }

}